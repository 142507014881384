import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import styles from './CustomTable.module.css';
import { useState } from 'react';

const CustomTable = ({ columns, data, action, ...props }) => {
    const [rowStates, setRowStates] = useState({});

    const handleRowProvinceAndCity = (rowId, field, value) => {
        setRowStates(prevStates => ({
            ...prevStates,
            [rowId]: {
                ...prevStates[rowId],
                [field]: value
            }
        }));
        // Modifica el valor directo de la data
        const findedOrder = data.find((order) => order.id === rowId);
        findedOrder.shipping_destination = {
            city_code: value?.city_code ? value?.city_code: rowStates[rowId]?.city?.city_code,
            city_name: value?.city_name ? value?.city_name: rowStates[rowId]?.city?.city_name,
            province_code: value?.province_code ? value?.province_code : rowStates[rowId]?.province.province_code,
            province_name: value?.province_name ? value?.province_name : rowStates[rowId]?.province.province_name,
            // Colombia codigo y nombre hardcode
            country_code: "170",
            country_name: "Colombia"

        }
    };



    const calculateTotal = (field) => {
        const toNumber = (str) => {
            if (typeof str !== 'string') return null;
            const num = parseFloat(str.replace(/\D/g, ''));
            return isNaN(num) ? null : num;
        };

        return data.reduce((sum, item) => {
            const num = toNumber(item[field]);
            return num !== null ? sum + num : sum;
        }, 0);
    };

    const totals = {};
    columns?.forEach(col => {
        if (col?.field && col?.hasTotal && data?.length > 0) {
            const total = calculateTotal(col.field);
            if (total !== 0) {
                totals[col.field] = total.toLocaleString();
            }
        }
    });

    const dynamicColumns = columns?.map(col => {
        const sortable = col?.hasOwnProperty('sortable') ? col?.sortable : false;
        const filterable = col?.hasOwnProperty('filterable') ? col?.filterable : false;
        const frozen = col?.hasOwnProperty('frozen') ? col?.frozen : false;
        const style = col?.hasOwnProperty('style') ? col?.style : {};
        const footer = totals[col?.field] ? totals[col?.field].toLocaleString() : "";

        const headerContent = typeof col?.header === 'function' ? col?.header() : col?.header;
        const header = <div className={styles.headerBold}>{headerContent}</div>;

        return (
            <Column
                key={col?.field || col?.header}
                field={col?.field}
                header={header}
                sortable={sortable}
                filter={filterable}
                style={style}
                body={typeof col?.body === 'function' ? (rowData) => col?.body(rowData, rowStates[rowData?.id], handleRowProvinceAndCity) : col?.body}
                footer={footer}
                frozen={frozen}
                alignFrozen={frozen ? "right" : undefined}
                selectionMode={col?.selectionMode}  // Añadir selección de modo si está definido
            />
        );
    });

    return (
        <DataTable
            className={styles.customTable}
            value={data}
            {...props}
            emptyMessage="No se encontraron resultados"
            scrollable
            onRowClick={(event) => action(event.data)}
        >
            {dynamicColumns}
        </DataTable>
    );
};

export default CustomTable;
