import { doc, updateDoc, arrayUnion, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { Button } from "primereact/button"; // Asegúrate de importar el botón correctamente
import CustomInputText from "../../../Components/FormComponents/CustomInputText/CustomInputText";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../Components/FormComponents/CustomSelect/CustomSelect";
import { useGlobalContext } from "../../../contexts/GlobalContext/GlobalContext";



export function ActionRevisarCambio(pqrs, extraPayload = {}) {
  const handleClick = async () => {
    // const docRef = doc(db, "pqrs", pqrs?.id); // Referencia al documento
    // const payload = {
    //   revisado: true,
    //   ...extraPayload
    // };

    try {
      // await updateDoc(docRef, {
      //   revisado: true,
      //   updatedAt: serverTimestamp()
      // });

      // await updateDoc(docRef, {
      //   actionHistory: arrayUnion({
      //     taskId: "cambio-1",
      //     payload,
      //     status: "done",
      //     executeAt: serverTimestamp()
      //   })
      // });

      console.log("Acción 'revisar cambio' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };

  return (
    <Button
      label="Acción"
      onClick={handleClick}
      className=" p-button-outlined"
    />
  );
}


export function ActionContactarCliente() {
  const handleClick = async () => {

    try {
      console.log("Acción 'actionContactarCliente' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };

  return (
    <div className="flex gap-4">
       <Button
          label="Contactar con mensaje inicial"
          onClick={handleClick}
          className=" p-button-outlined"
        />
      <Button
        label="Abrir chat interno"
        onClick={handleClick}
        className=" p-button-outlined"
      />

    </div>
  );
}

export function ActionSeleccionarTipoPQR() {
  const handleCambio = async () => {
    try {
      console.log("Acción 'handleCambio' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  const handkleGarantia = async () => {
    try {
      console.log("Acción 'actionContactarCliente' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  const handleRetracto = async () => {
    try {
      console.log("Acción 'actionContactarCliente' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };

  return (
    <div className="flex gap-4">
       <Button
          label="Cambio"
          onClick={handleCambio}
          className=" p-button-outlined"
        />
      <Button
        label="Garantia"
        onClick={handkleGarantia}
        className=" p-button-outlined"
      />
      <Button
        label="Retracto"
        onClick={handleRetracto}
        className=" p-button-outlined"
      />
    </div>
  );
}

export function ActionCerrarPorTiempoEspera() {
  const handleCerrarPorTiempo = async () => {
    try {
      console.log("Acción 'handleCerrarPorTiempo' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  return (
    <Button
      label="Cerrar PQR"
      onClick={handleCerrarPorTiempo}
      className=" p-button-outlined"
    />
  );
}


export function ActionAñadirSolucionPedido() {
      const { t } = useTranslation("MyOrders");

  const handleAñadirSolucionPedido = async (answer) => {
    try {
      console.log("Acción 'handleAñadirSolucionPedido' ejecutada y registrada", answer);
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
 
  return (
    <div className="flex">
        <CustomInputText
          // value={rowData.note || ""}
          // onChange={(e) => handleRowChange(rowData, "note", e.target.value)}
          placeholder={t("input-placeholders.idOrder")}
          // disabled={!rowEditStates[rowData.id]}
          inColumTable={true}
        />
      <Button
        label="Si"
        onClick={() =>handleAñadirSolucionPedido("Si")}
        className=" p-button-outlined"
      />
      <Button
        label="No"
        onClick={() => handleAñadirSolucionPedido("No")}
        className=" p-button-outlined"
      />
    </div>
  );
}
export function ActionCargarGuiaEnviadaPorCliente() {
  const { t } = useTranslation("MyOrders");
  const { shippingCompanies } = useGlobalContext();
const handleAñadirSolucionPedido = async (answer) => {
try {
  console.log("Acción 'handleAñadirSolucionPedido' ejecutada y registrada", answer);
} catch (error) {
  console.error("Error al ejecutar", error);
  throw error;
}
};

return (
<div className="flex">
    <CustomInputText
      // value={rowData.note || ""}
      // onChange={(e) => handleRowChange(rowData, "note", e.target.value)}
      placeholder={t("input-placeholders.idOrder")}
      // disabled={!rowEditStates[rowData.id]}
      inColumTable={true}
    />
     <CustomSelect
      optionLabel={"name"}
      options={shippingCompanies}
      // value={rowData.shipping_address_type}
      // onChange={(e) => handleRowChange(rowData, "shipping_address_type", e.value)}
      placeholder={t("input-placeholders.shippingAddressType")}
      // disabled={!rowEditStates[rowData.id]}
      inColumTable={true}
    />
  <Button
    label="Si"
    onClick={() =>handleAñadirSolucionPedido("Si")}
    className=" p-button-outlined"
  />
  <Button
    label="No"
    onClick={() => handleAñadirSolucionPedido("No")}
    className=" p-button-outlined"
  />
</div>
);
}
export function ActionProcedePQR() {
  const { t } = useTranslation("MyOrders");

  const handleProcedePQR = async () => {
    try {
      console.log("Acción 'ActionProcedePQR' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  return (
    <div>
      <CustomInputText
        // value={rowData.note || ""}
        // onChange={(e) => handleRowChange(rowData, "note", e.target.value)}
        placeholder={t("input-placeholders.code")}
        // disabled={!rowEditStates[rowData.id]}
        inColumTable={true}
      />
      <Button
        label="Cerrar PQR"
        onClick={handleProcedePQR}
        className=" p-button-outlined"
      />
    </div>
  );
}
export function ActionEmpacar() {
  const handleProcedePQR = async () => {
    try {
      console.log("Acción 'ActionEmpacar' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  return (
    <div>
      <Button
        label="Marcar como empacado"
        onClick={handleProcedePQR}
        className=" p-button-outlined"
      />
    </div>
  );
}
export function ActionCargarCuentaBancariaCliente() {

  const handleCargarCuentaBancariaCliente = async () => {
    try {
      console.log("Acción 'handleCargarCuentaBancariaCliente' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  const handleDescargarSoporte = async () => {
    try {
      console.log("Acción 'handleDescargarSoporte' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  return (
    <div>
      <Button
        label="Cargar soporte"
        onClick={handleCargarCuentaBancariaCliente}
        className=" p-button-outlined"
      />
      <Button
        label="Descargar soporte"
        onClick={handleDescargarSoporte}
        className=" p-button-outlined"
      />
    </div>
  );
}
export function ActionMontoADevolver() {
  return (
    <div>
     {"MONTO A DEVOLVER : 000"}
    </div>
  );
}
export function ActionCargarComprobanteTransferenciaRetracto() {

  const handleCargarSoporte = async () => {
    try {
      console.log("Acción 'handleCargarSoporte' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  const handleDescargarSoporte = async () => {
    try {
      console.log("Acción 'handleDescargarSoporte' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };

  return (
    <div>
      <Button
        label="Cargar soporte"
        onClick={handleCargarSoporte}
        className=" p-button-outlined"
      />
      <Button
        label="Descargar soporte"
        onClick={handleDescargarSoporte}
        className=" p-button-outlined"
      />
    </div>
  );
}
export function ActionEnviarComprobanteTransferenciaCliente() {

  const handleEnviarComprobanteTransferenciaCliente = async () => {
    try {
      console.log("Acción 'ActionEnviarComprobanteTransferenciaCliente' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  return (
    <div>
      <Button
        label="Enviar comprobante"
        onClick={handleEnviarComprobanteTransferenciaCliente}
        className=" p-button-outlined"
      />
    </div>
  );
}
export function ActionCargarConsecutivoNotaCredito() {
  const { t } = useTranslation("MyOrders");

  const handleGuardarConsecutivo = async () => {
    try {
      console.log("Acción 'ActionProcedePQR' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };
  return (
    <div>
      <CustomInputText
        // value={rowData.note || ""}
        // onChange={(e) => handleRowChange(rowData, "note", e.target.value)}
        placeholder={t("input-placeholders.consecutiveNumber")}
        // disabled={!rowEditStates[rowData.id]}
        inColumTable={true}
      />
      <Button
        label="Guardar"
        onClick={handleGuardarConsecutivo}
        className=" p-button-outlined"
      />
    </div>
  );
}
