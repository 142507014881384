/* eslint-disable react/prop-types */
import { IoIosArrowDown, IoIosCamera, IoIosVolumeHigh } from "react-icons/io";
import styles from "./ChatMessages.module.css";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { MdDownload } from "react-icons/md";
import { IoDocumentOutline } from "react-icons/io5";

// import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { MdOutlineSmsFailed } from "react-icons/md";

import { IoCheckmarkOutline } from "react-icons/io5";

import { TieredMenu } from "primereact/tieredmenu";
import { useRef } from "react";
import { useChatMenu } from "../ChatMenuContext";
import { FaVideo } from "react-icons/fa";
import formatDateES from "../../../../utils/formatDateES";

export const ChatMessage = ({
  status, messageId, avatarSrc, username, time, children, isSent, messageText, videoSrc, audioSrc, 
  imageSrc, isReplying, typeRepliying, type, videoSrcRepliying, imgSrcRepliying, messageTextRepliying, idMessageRepliying,audioDuration
  }) => {
    const responseMode = isReplying ? true : false;
    const messageClass = isSent ? styles.sent : styles.received;
    const menu = useRef(null);

    const { menuChatOptions, selectedMenuOption, handleChatMessageMenu, scrollToElementById } = useChatMenu(); // Usa el hook
    const menuItems = menuChatOptions(selectedMenuOption);

    const renderReplyMedia = () => {
      switch (typeRepliying) {
        case "video":
          return (
            <video className={styles.thumbnail} muted>
              <source src={videoSrcRepliying} type="video/mp4" />
              Tu navegador no soporta el elemento de video.
            </video>
          );
        case "image":
          return <img src={imgSrcRepliying} alt="Contenido de la respuesta" className={styles.thumbnail} />;
        default:
          return null;
      }
    };

    return (
      <div id={messageId} className={`${styles.chatMessageContainer} ${messageClass}`}>
        <img src={avatarSrc} alt={username} className={styles.avatar} />
        <div className={styles.messageContent}>
          <div className={styles.messageHeader}>
            <span className={styles.username}>{username}</span>
            <div className={styles.messageHeaderTools}>
              <span className={styles.time}>{time ? formatDateES(time): ""}</span>
              <TieredMenu model={menuItems} popup ref={menu} breakpoint="767px" />
              <button
                className={`${styles.chatMenuOptionBtn} ${messageClass}`}
                onClick={(e) => handleChatMessageMenu(
                {
                  username,
                  messageId,
                  messageText,
                  videoSrc,
                  audioSrc,
                  imageSrc,
                  type,
                  audioDuration,
                  event: menu.current.toggle(e)
                })}
              >
                <IoIosArrowDown />
              </button>
            </div>
          </div>
          {responseMode === true && (
            <div>
                  {responseMode && (
                      <div className={styles.chatInputContainerResponse} onClick={() => scrollToElementById(idMessageRepliying)}>
                        <div className={styles.responseHeader}>
                          <span className={styles.usernameResponse}>{username}</span>
                        </div>
                        <div className={styles.messageTextContainerResponse}>
                        <span className={styles.messageTextRepliying}>
                            {messageTextRepliying}
                            {typeRepliying === "image" && messageTextRepliying === "" && <> <IoIosCamera className={styles.icon} /></>}
                            {typeRepliying === "audio" && <div className={styles.audioIconContainer}> <IoIosVolumeHigh className={styles.icon} /></div>}
                            {typeRepliying === "video" &&  <div > <FaVideo className={styles.icon} /> </div>}
                            {typeRepliying === "document" &&  <> <IoDocumentOutline  className={styles.icon} /> </>}
                          </span>
                      
                          {renderReplyMedia()}
                          
                        </div>
                      </div>
                  )}
                {/* Resto del contenido del mensaje */}
              </div>
            )}
      
          <div className={styles.messageText}>
            {type === "video" || type === "image" ? messageText : ""}

            {children} {/* Aquí se inserta el contenido específico de cada tipo de mensaje */}

            <span className={styles.statusMessageCheck}>
              {status?.status === "failed" && <MdOutlineSmsFailed  className={styles.icon} />}
              {(status?.status === undefined && isSent)  && <MdOutlineSmsFailed className={styles.icon} /> }
              {status?.status === "sent" && <IoCheckmarkOutline className={styles.icon} />}
              {(status?.status === "delivered" || !isSent && status?.status === undefined) && <IoCheckmarkDoneSharp className={styles.icon} />  }
              {status?.status === "read" && <IoCheckmarkDoneSharp className={styles.icon} style={{color:"blue"}} />}
            </span>
          </div>
        </div>
      </div>
    );
};

export const ChatMessageText = ({
    status, audioDuration, type, avatarSrc, username, time, messageText, isSent, messageId,
    isReplying, typeRepliying,  messageTextRepliying, imgSrcRepliying, idMessageRepliying, videoSrcRepliying
  }) => {
  
    return (
    <ChatMessage
      status={status}
      avatarSrc={avatarSrc}
      username={username}
      time={time}
      isSent={isSent} 
      messageId={messageId}
      messageText={messageText}
      isReplying={isReplying}
      typeRepliying={typeRepliying}
      messageTextRepliying={messageTextRepliying}
      type={type}
      imgSrcRepliying={imgSrcRepliying}
      videoSrcRepliying={videoSrcRepliying}
      idMessageRepliying={idMessageRepliying}
      audioDuration={audioDuration}
    >
      <span>{messageText}</span>
    </ChatMessage>
  );
};


export const ChatVideoMessage = ({
    messageTextRepliying, status, audioDuration, idMessageRepliying, type, avatarSrc,
    messageText, username, time, videoSrc, isSent, messageId, isReplying, typeRepliying, videoSrcRepliying, imgSrcRepliying
  }) => {
  return (
    <>
      <ChatMessage
        status={status}
        avatarSrc={avatarSrc}
        username={username}
        time={time}
        isSent={isSent}
        videoSrc={videoSrc}
        messageId={messageId}
        messageText={messageText}
        isReplying={isReplying}
        typeRepliying={typeRepliying}
        type={type}
        imgSrcRepliying={imgSrcRepliying}
        videoSrcRepliying={videoSrcRepliying}
        idMessageRepliying={idMessageRepliying}
        audioDuration={audioDuration}
        messageTextRepliying={messageTextRepliying}

      >
          {messageText}
          
          <video controls className={styles.video}>
            <source src={videoSrc} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
        </ChatMessage>
    
    </>
  );
};

export const ChatAudioMessage = ({
    isReplying,
    messageTextRepliying, status, audioDuration, idMessageRepliying, type,
    avatarSrc, username, time, audioSrc, messageText, isSent, messageId,
    contentRepliying, typeRepliying
  }) => {
  return (
      <ChatMessage
        isReplying={isReplying}
        status={status}
        audioDuration={audioDuration}
        typeRepliying={typeRepliying}
        messageTextRepliying={messageTextRepliying}
        imgSrcRepliying={contentRepliying}
        videoSrcRepliying={contentRepliying}
        idMessageRepliying={idMessageRepliying} 
        type={type} avatarSrc={avatarSrc} username={username} time={time} isSent={isSent} 
        audioSrc={audioSrc} messageText={messageText}  messageId={messageId}>
      <div>
        <span>{messageText}</span>
        <audio controls className={styles.audioPlayer}>
          <source src={audioSrc} type="audio/mp3" />
          Tu navegador no soporta el elemento de audio.
        </audio>
      </div>
    </ChatMessage>
  );
};

export const ChatImageMessage = ({
    messageTextRepliying, status, audioDuration, idMessageRepliying, type, avatarSrc, username,
    time, imageSrc, isSent, messageId, messageText, isReplying ,typeRepliying, imgSrcRepliying, videoSrcRepliying,
  }) => {
  return (
    <ChatMessage 
      status={status}
      audioDuration={audioDuration}
      avatarSrc={avatarSrc}
      username={username}
      time={time}
      isSent={isSent}
      messageId={messageId}
      messageText={messageText}
      imageSrc={imageSrc}
      isReplying={isReplying}
      typeRepliying={typeRepliying}
      imgSrcRepliying={imgSrcRepliying}
      videoSrcRepliying={videoSrcRepliying}
      type={type}
      messageTextRepliying={messageTextRepliying}
      idMessageRepliying={idMessageRepliying}
      >

      <img src={imageSrc} alt={`Image from ${username}`} className={styles.imageContent} />
    </ChatMessage>
  );
};


export const ChatDocumentMessage = ({
  messageTextRepliying,
  status,
  audioDuration,
  idMessageRepliying,
  type,
  avatarSrc,
  username,
  time,
  documentName,
  documentUrl,
  isSent,
  messageId,
  messageText,
  isReplying,
  typeRepliying,
  imgSrcRepliying,
  videoSrcRepliying,
}) => {
  return (
    <ChatMessage
      status={status}
      audioDuration={audioDuration}
      avatarSrc={avatarSrc}
      username={username}
      time={time}
      isSent={isSent}
      messageId={messageId}
      messageText={messageText}
      isReplying={isReplying}
      typeRepliying={typeRepliying}
      imgSrcRepliying={imgSrcRepliying}
      videoSrcRepliying={videoSrcRepliying}
      type={type}
      messageTextRepliying={messageTextRepliying}
      idMessageRepliying={idMessageRepliying}
    >
      <div className={styles.documentContent}>
        <div>
          <IoDocumentOutline style={{fontSize:"1.7rem"}}/>

        </div>

        <div className={styles.documentInfo}>
          <div className={styles.documentName}>
            {messageText}
          </div>
          <span className={styles.documentName}>{documentName}</span>
          <div className={styles.downloadLink}>
            <a
              href={documentUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdDownload style={{fontSize:"1.7rem"}}/>
              </a>
          </div>
        </div>
      </div>
    </ChatMessage>
  );
};