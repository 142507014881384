import {  MdOutlinePause } from 'react-icons/md';
import styles from "./VoiceMediaRecorderBtn.module.css";
import { useChatMenu } from '../ChatMenuContext';
import { ReactMic } from 'react-mic';

// eslint-disable-next-line react/prop-types
const VoiceMediaRecorderBtn = () => {

    const {audioUrl, stopHandle,  voice } = useChatMenu()

    return (
        <div 
            className={styles.voiceMediaRecorder}
            >
            <div>
               {!audioUrl && ( <ReactMic
                        record={voice}
                        className="sound-wave w-full "
                        strokeColor="#000000"
                        mimeType='audio/mpeg'
                    />)}
                </div>
            <div 
                className={styles.recordAudioBtn}
                >
                    { voice && (
                    <button onClick={stopHandle}>
                        <MdOutlinePause
                            style={{
                                backgroundColor: "transparent",
                                color: "inherit",
                                border: "none",
                                boxShadow: "none",
                                cursor: "pointer",
                                fontSize: "27px",
                                marginLeft: "12px",
                                marginRight: "6px"
                            }}
                        />
                    </button>
                )}
            </div>
        </div>
    );
};

export default VoiceMediaRecorderBtn;

