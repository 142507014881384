/* eslint-disable react/prop-types */

const CustomButton = ({
    style,
    onClick,
    label = "Submit", // Texto por defecto
    disabled,
  }) => {
    const newStyle = {
        ...style,
        color: "#fff", // Texto blanco
        border: "none",
        borderRadius: "5px",
        padding: "10px 20px",
        fontSize: "16px",
        fontWeight: "bold",
        cursor: "pointer",
        textAlign: "center",
        backgroundColor: "#1a73e8", // Color azul por defecto
        justifyContent: "center", // Color azul por defecto
        opacity: disabled ? "40%": "100%" 
    }
    return (
      <button
        onClick={onClick}
        style={newStyle} // Aplicando los estilos en línea
        disabled={disabled}
      >
        {label}
      </button>
    );
  };
  
  export default CustomButton;
  