import {
    ActionAñadirSolucionPedido,
    ActionCargarComprobanteTransferenciaRetracto,
    ActionCargarConsecutivoNotaCredito,
    ActionCargarCuentaBancariaCliente,
    ActionCargarGuiaEnviadaPorCliente,
    ActionCerrarPorTiempoEspera,
    ActionContactarCliente,
    ActionEnviarComprobanteTransferenciaCliente,
    ActionMontoADevolver,
    ActionProcedePQR,
    ActionRevisarCambio,
    ActionSeleccionarTipoPQR } from "./actions";

export const TASKS_CONFIG = {
    RECIBIDO: [
        {
            id: "1",
            label: "Contactar cliente",
            actions:  [ActionContactarCliente]
        },
        {
            id: "2",
            label: "Seleccionar tipo de PQR",
            actions: [ActionSeleccionarTipoPQR]
        },
        {
            id: "3",
            label: "Cerrar por exceso de tiempo de espera",
            actions: [ActionCerrarPorTiempoEspera]
        },
    ],
    CAMBIO: [
        {
            id: "1",
            label: "Contactar cliente",
            actions: [ActionContactarCliente]
        },
        {
            id: "2",
            label: "¿Añadir solucion a nuevo pedido?",
            actions: [ActionAñadirSolucionPedido]
        },
        {
            id: "3",
            label: "Ingresar items requeridos",
            actions: [ActionRevisarCambio]
        },
        {
            id: "4",
            label: "Politica de envio PQR",
            actions: [ActionRevisarCambio]
        },
        {
            id: "5",
            label: "Cargar guia enviada por cliente",
            actions: [ActionCargarGuiaEnviadaPorCliente]
        },
        {
            id: "6",
            label: "¿Procede el PQR?",
            actions: [ActionProcedePQR]
        },
        {
            id: "7",
            label: "Empacar",
            actions: [ActionRevisarCambio]
        },
        {
            id: "8",
            label: "Cargar guia envio hacia cliente",
            actions: [ActionRevisarCambio]
        },
        {
            id: "9",
            label: "Enviar guia envio hacia cliente",
            actions: [ActionRevisarCambio]
        },
        {
            id: "10",
            label: "Cerrar por exceso de tiempo de espera",
            actions: [ActionCerrarPorTiempoEspera]
        },
    ],
    GARANTIA: [
        {
            id: "1",
            label: "Contactar cliente",
            actions: [ActionContactarCliente]
        },
        {
            id: "2",
            label: "¿Añadir solucion a nuevo pedido?",
            actions: [ActionAñadirSolucionPedido]
        },
        {
            id: "3",
            label: "Ingresar items requeridos",
            actions: [ActionRevisarCambio]
        },
        {
            id: "4",
            label: "Politica de envio PQR",
            actions: [ActionRevisarCambio]
        },
        {
            id: "5",
            label: "Cargar guia enviada por cliente",
            actions: [ActionCargarGuiaEnviadaPorCliente]
        },
        {
            id: "6",
            label: "¿Procede el PQR?",
            actions: [ActionProcedePQR]
        },
        {
            id: "7",
            label: "Empacar",
            actions: [ActionRevisarCambio]
        },
        {
            id: "8",
            label: "Cargar guia envio hacia cliente",
            actions: [ActionRevisarCambio]
        },
        {
            id: "9",
            label: "Enviar guia envio hacia cliente",
            actions: [ActionRevisarCambio]
        },
        {
            id: "10",
            label: "Cerrar por exceso de tiempo de espera",
            actions: [ActionCerrarPorTiempoEspera]
        },
    ],
    RETRACTO: [
        {
            id: "1",
            label: "Contactar cliente",
            actions: [ActionContactarCliente]
        },
        {
            id: "2",
            label: "Cargar guia enviada por cliente",
            actions: [ActionCargarGuiaEnviadaPorCliente]
        },
        {
            id: "3",
            label: "¿Procede el PQR?",
            actions: [ActionProcedePQR]
        },
        {
            id: "4",
            label: "Cargar cuenta bancaria cliente",
            actions: [ActionCargarCuentaBancariaCliente]
        },
        {
            id: "5",
            label: "Monto a devolver",
            actions: [ActionMontoADevolver]
        },
        {
            id: "6",
            label: "Cargar comprobante transferencia de retracto",
            actions: [ActionCargarComprobanteTransferenciaRetracto]
        },
        {
            id: "7",
            label: "Enviar comprobante de transferencia al cliente",
            actions: [ActionEnviarComprobanteTransferenciaCliente]
        },
        {
            id: "8",
            label: "Cargar consecutivo NOTA CREDITO",
            actions: [ActionCargarConsecutivoNotaCredito]
        },
        {
            id: "9",
            label: "Cerrar por exceso de tiempo de espera",
            actions: [ActionCerrarPorTiempoEspera]
        },
       
    ],
}