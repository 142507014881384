import PropTypes from "prop-types"
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import MainButton from "../MainButton/MainButton.jsx";
import { useTranslation } from "react-i18next";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import { generateRandomId } from "../../utils/generateRandomId.js";
import { defProvinces } from "../../utils/defProvinces.js";
import { fetchFromCloudFunction } from "../../services/cloudFunctinons/fetchFromCloudFunction.js";
import toastFunction from "../../utils/toastFunction.js";
import BadgetCard from "../BadgetCard/BadgetCard.jsx";
import UserMoneyDataCard from "../UserMoneyDataCard/UserMoneyDataCard.jsx";
import numeral from "numeral";
import useMatchProvinceAndCity from "../../hooks/useMatchProvinceAndCity.js";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import { useNavigate } from "react-router-dom";

const CheckoutShippingCartForm = ({toast, onClose, isCatalog, catalogData}) => {
    const { t } = useTranslation("Products");
    const {shoppingCart, setShoppingCart, countries} = useGlobalContext();
    const { getDocumentById } = useFirebaseCRUD();
    const { userData, userSellerOwnerData} = useAuthContext();
    // Informacion del documento del dueño 
    const userOwnerData = userSellerOwnerData   ||  userData
    const { globalLoading, setGlobalLoading } = useLoaderContext();
    const [ relatedCustomers, setRelatedCustomers] = useState();
    const [ selectedCustomer, setSelectedCustomer] = useState();
    const [ country, setCountry ] = useState({ name: "Colombia", code: "+57" });
    const [ department, setDepartment ] = useState();
    const {cities, zones} = useMatchProvinceAndCity(department);

    const [ selectedCity, setSelectedCity ] = useState();
    const [ totalProductsValue, setTotalProductsValue ] = useState(0);
    const [ tableColumnsData, setTableColumnsData] = useState([]);

    const relatedShippingReturnBusinessAddress = {
      shipping_return_business_address : userOwnerData?.shipping_return_business_address,
      shipping_return_city : userOwnerData?.shipping_return_city,
      shipping_return_province : userOwnerData?.shipping_return_province,
    }
    const relatedbusinessAddress = {
      business_address : isCatalog ? null: userOwnerData?.business_address,
      city :isCatalog ?  null:  userOwnerData?.shipping_return_city,
      province : isCatalog ? null : userOwnerData?.shipping_return_province,
      country : isCatalog ? catalogData?.contact_country: userOwnerData?.country,
    }
    const [ relatedGroupsItemsOrigins, setRelatedGroupsItemsOrigins] = useState([]);
    
    const [ checkoutValues, setCheckoutValues ] = useState({ 
      id: generateRandomId(),
      customer_first_name: "",
      customer_last_name: "",
      subtotal: 0,
      products_details: shoppingCart,
      related_items_groups_origins: relatedGroupsItemsOrigins,
      catalog_id: catalogData?.id || null,
      is_catalog: isCatalog,
      free_shippings: catalogData?.free_shippings ?? false,
      total_products_discount: 0,
      total_value: 0,
      customer_id: "",
      shipping_info: {
          price: 0,
          name: "",
          servicePayment: 0
      },
      shipping_discount: 0,
      store_id: "",
      store_name: "",
      payment_method: {
          name: "COD",
          code: 1
      },
      related_seller_id: isCatalog ? catalogData?.user_id : userOwnerData?.uid,
      related_seller_business_id: isCatalog ? catalogData?.business_id:  userOwnerData?.business_id, 

      related_seller_shipping_return_address: relatedShippingReturnBusinessAddress, 
      related_seller_business_address: relatedbusinessAddress, 

      country_code: country.code,
      customer_phone_number: "",
      contact_email: "",
      customer_shipping_addres_line_1: "",
      customer_shipping_addres_line_2: "",
      shipping_destination: null
    });
    const navigate = useNavigate();

  // Función para manejar el cambio en la dirección de envío del cliente
  const handleCheckoutValueChange = (fieldName, newValue) => {
      // Actualiza el estado de checkoutValues con la dirección de envío actualizada
      setCheckoutValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
      }));
  };
  
  const syncCustomerDataValues = (customerData, productsShippingQuotes) => {
    let customerEmail = null;
    let customerPhone = null;
    let customerAddress = null;

    // console.log("Verificacion", customerAddress, customerEmail)
    if (customerData?.emails?.length > 0) {
      customerEmail = customerData.emails[0];
    }
    // console.log("Verificacion", customerAddress, customerEmail)

    if (customerData?.phones?.length > 0) {
      customerPhone = customerData.phones[0];
    }
    if (customerData.shipping_address.length > 0) {
      customerAddress = customerData.shipping_address[0];
    }
    // console.log("Verificacion 2 ", customerAddress, customerEmail)
    const firstCustomerSHippingAddress = customerData.shipping_address[0];
    setDepartment(firstCustomerSHippingAddress.state);
    setSelectedCity(firstCustomerSHippingAddress.city)
    setSelectedCustomer(customerData);
      setCheckoutValues((prevValues) => ({
      ...prevValues,
      customer_phone_number: customerPhone,
      contact_email: customerEmail,
      country_code: customerData.country_code,
      customer_shipping_addres_line_1: `${customerAddress.address_lines_one}  ${customerAddress.address_lines_two}`,
      customer_first_name: customerData.first_names,
      customer_last_name: customerData.last_names,
      customer_id: customerData.id,
      related_items_groups_origins: productsShippingQuotes,
    }));
  }
  // Funcion para obtener los clientes relacionados al usuario
  const getCustomers = async () => {
    setGlobalLoading(true);
    const relatedStores = userOwnerData?.seller_stores || [];
    const relatedStoresIds = relatedStores.map((store) => store.id);
    try {
        // Referencia a la colección "customers" en la base de datos
        const customersCol = collection(db, "customers");
       
        // Consulta para obtener clientes filtrados por IDs de tiendas, ordenados por fecha de creación
        const q = query(customersCol, where("related_stores_ids", "array-contains-any", relatedStoresIds),orderBy("created_at", "asc"));

        // Establecer un "escucha" para la consulta, actualizando el estado cuando cambian los datos
        const unsubscribeCustomers = onSnapshot(q, async (snapshot) => {
        const shoppexCustomers = [];
        // Iterar sobre los documentos devueltos por la consulta
        snapshot.forEach((doc) => {
            shoppexCustomers.push(doc.data());
        });

        // Actualizar el estado de los clientes (si es necesario)
        setRelatedCustomers([{first_names: "Tus clientes", id: 1, emails: [], phones:[]}, ...shoppexCustomers]);
        
      });
        setGlobalLoading(false);
        return unsubscribeCustomers; // Devuelve la función de "desuscripción" para detener el escucha cuando sea necesario
    } catch (error) {
        return () => {};
    }
  };
  console.log("checkoutValues", checkoutValues);

  const getBody = () => {
    const userStores = userOwnerData?.seller_stores.length > 0 ? userOwnerData?.seller_stores : null;
    let findedDedaultStore = null;
    let shippingDestination = null;
    shippingDestination = {
      country_code: country.code,
      country_name: country.name,
      ...selectedCity,
      ...department,
    }
    if (userStores) {
       findedDedaultStore = userStores?.find((store) => (store.platform?.code === 3 && store?.is_default));
       checkoutValues.store_id = findedDedaultStore?.id
       checkoutValues.store_name = findedDedaultStore?.name
    } else {
       checkoutValues.store_id = catalogData?.default_store_id
       checkoutValues.store_name = "DEFAULT"
    }
   

    checkoutValues.total_value = totalProductsValue;
    checkoutValues.subtotal = totalProductsValue;
    
    checkoutValues.shipping_destination = shippingDestination;
    checkoutValues.related_items_groups_origins = relatedGroupsItemsOrigins
    const firstShippingAddress = selectedCustomer?.shipping_address?.length > 0 ? selectedCustomer.shipping_address[0] : null;
    const shippingAddressOfNewCustomer = {
      id: firstShippingAddress?.id || generateRandomId(),
      current_address: false,
      name: firstShippingAddress?.name || "",
      country: firstShippingAddress?.country || country.name,
      state: firstShippingAddress?.state || {
          province_code: shippingDestination.province_code, 
          province_name: shippingDestination.province_name
        },
      city: firstShippingAddress?.city || {
        city_code: shippingDestination.city_code,
        city_name: shippingDestination.city_name,
      },
      address_lines_one: firstShippingAddress?.address_lines_one || checkoutValues.customer_shipping_addres_line_1,
      address_lines_two: firstShippingAddress?.address_lines_two || checkoutValues.customer_shipping_addres_line_2,
      zip_code: firstShippingAddress?.zip_code || null,
    }
    const customerValues = {
      shopify_id: null,
      customer_shopify_id: null,
      first_names: checkoutValues.customer_first_name,
      last_names: checkoutValues.customer_last_name,
      country_code: checkoutValues.country_code,
      shipping_address: [shippingAddressOfNewCustomer],
      billing_data: [],
      tags: [],
    } 
    const body  = {
      customerValues, 
      checkoutValues,
      origin: "simpleSplited" // Dividir la orden
    }

    console.log("return body", body)
    return body;
  };

  const calculateValues = (resultsProductsShippingQuotes = []) => {
    let totalSalePrice = 0;
    let shippingCost = 0
    let totalCostPrice = 0;
    let revenue = 0;

    console.log("resultsProductsShippingQuotes", resultsProductsShippingQuotes)
    // console.log("validacion",  resultsProductsShippingQuotes?.length > 0 , (!catalogData?.free_shippings && !isCatalog), !catalogData?.free_shippings, !isCatalog)

    shoppingCart.forEach((item) => {
      console.log("Item en el carrito", item)
      const salePrice = item?.total_item_price || 0;
      const costPrice = (() => {
        if (isCatalog) {
          if (item?.custom_cost) {
            return item.custom_cost * item?.quantity;
          } else {
            return (item?.suggested_retail_price || 0) * item?.quantity;
          }
        } else {
          // Cuando isCatalog es falso, se utiliza el cálculo basado en Impuestos y precio del proveedor
          return ((item?.unitary_commercial_partner_charge_with_VAT_with_service_fee))* item?.quantity;
        }
      })();
      

      const sellerRevenue = salePrice - costPrice;
      
      revenue += sellerRevenue;
      totalCostPrice += costPrice;
      totalSalePrice += salePrice;
    });

  
    if (
      (resultsProductsShippingQuotes?.length > 0 && !isCatalog) || 
      (resultsProductsShippingQuotes?.length > 0 && !catalogData?.free_shippings) // Si esta en el catalogo y tiene envios gratis no sumamos 
    ) {
        resultsProductsShippingQuotes?.forEach((shippingResults) => {
          shippingCost += shippingResults.quote.price + shippingResults.quote.servicePayment;
        });
    }
    // Sumamos el costo del flete al valor total
    totalSalePrice += shippingCost;
    // console.log("shippingCost", shippingCost)

      let tableData = [
        {
          label : `Costo productos (${shoppingCart?.length})`,
          value : numeral(totalCostPrice).format('$ 0,0[.]')
          },
          {
          label : `Envio (${resultsProductsShippingQuotes?.length})`,
          value : numeral(shippingCost).format('$ 0,0[.]')
          },
          {
          label : "Tu ganancia",
          value : numeral(revenue).format('$ 0,0[.]')
          },
          {
          label : "TU CLIENTE PAGA EN TOTAL",
          value : numeral(totalSalePrice).format('$ 0,0[.]')
          },
      ]
      if (isCatalog) {
        tableData = [
          {
            label: `Costo productos (${shoppingCart?.length})`,
            value: numeral(totalSalePrice - shippingCost).format('$ 0,0[.]'),
          },
          {
            label: "PAGAS EN TOTAL",
            value: numeral(totalSalePrice).format('$ 0,0[.]'),
          },
        ];
      
        // Solo agregar el objeto "Envio" si catalogData?.free_shippings es true
        if (!catalogData?.free_shippings && isCatalog) {
          tableData.splice(1, 0, { // Inserta en la posición 1 (después de "Costo productos")
            label: `Envio (${resultsProductsShippingQuotes?.length})`,
            value: numeral(shippingCost).format('$ 0,0[.]'),
          });
        }
      }
      
      setTotalProductsValue(totalSalePrice)
      setTableColumnsData(tableData)
  };
  

  const cleanCustomerFilds = () => {
     setDepartment([]);
      setSelectedCity([])
        setCheckoutValues((prevValues) => ({
        ...prevValues,
        customer_phone_number: "",
        contact_email: "",
        country_code: "",
        customer_shipping_addres_line_1: "",
        customer_first_name: "",
        customer_last_name: "",
        customer_id: null,
      }));
  };

  const handleCustomerSelect = async (customerData) => {
    if (customerData.id !== 1 || !customerData.id) {
      syncCustomerDataValues(customerData)
      const destinyCustomerCityCode = customerData?.shipping_address && customerData?.shipping_address.length > 0
      ? customerData.shipping_address[0].city.city_code
      : null;
      const amountShippingAndValues =  amountDispatchDestinies(checkoutValues.products_details)
      await quoteProducts(amountShippingAndValues, destinyCustomerCityCode)  
    } else {
      setSelectedCustomer(customerData);
      cleanCustomerFilds()
    }

  };
  
  const amountDispatchDestinies = (productDetails) => {
    // Objeto para acumular los valores por dirección y vendedor
    const addressTotals = {};
  
    // Recorrer los detalles de los productos y acumular los valores
    productDetails.forEach((product) => {
        // Obtener el precio total del artículo o usar 0 si no está definido
        const costPrice = product.total_item_price || 0;
        // Obtener la ID de la dirección relacionada
        const addressId = product.related_shipping_address_id;
        // Obtener la ID del vendedor relacionado
        const sellerId = product.related_seller_id;
  
        // Si la dirección no está en el objeto, inicializarla
        if (!addressTotals[addressId]) {
            addressTotals[addressId] = {
                ...product, // Copiar todas las propiedades del producto
                totalAmount: 0, // Inicializar el monto total
                related_seller_id: sellerId, // Asignar el ID del vendedor
                related_items_ids: new Set(), // Inicializar un conjunto para IDs de artículos relacionados
                quote: null, // Agregar un campo para la cotización
                productNames: new Set(), // Inicializar un conjunto para nombres de productos
            };
        }
  
        // Acumular el valor para la dirección específica
        addressTotals[addressId].totalAmount += costPrice; // Sumar el precio del artículo al monto total
        addressTotals[addressId].related_items_ids.add(product.id); // Agregar el ID del artículo al conjunto
        addressTotals[addressId].productNames.add(product.product_title); // Agregar el nombre del producto al conjunto
    });
  
    // Convertir el objeto de resultados en un array
    const resultArray = Object.keys(addressTotals).map((addressId) => ({
        related_shipping_address_id: addressId, // ID de la dirección
        totalAmount: addressTotals[addressId].totalAmount, // Monto total acumulado
        related_seller_id: addressTotals[addressId].related_seller_id, // ID del vendedor
        related_items_ids: Array.from(addressTotals[addressId].related_items_ids), // Convertir el conjunto de IDs de artículos en un array
        quote: addressTotals[addressId].quote, // Incluir el campo de cotización
        productNames: Array.from(addressTotals[addressId].productNames), // Convertir el conjunto de nombres de productos en un array
    }));

    // Actualizar el estado con los grupos de artículos relacionados
    setRelatedGroupsItemsOrigins(resultArray);
    // Imprimir el resultado en la consola
    console.log("amountShippingAndValues", resultArray);

    // Devolver el array de resultados
    return resultArray;
};

  
const quoteProducts = async (amountShippingAndValues, destinyCustomerCityCode) => {
  setGlobalLoading(true); // Establecer el estado de carga global a true
  const results = []; // Inicializar un array para almacenar los resultados

  // Mapeamos las llamadas asíncronas a un array de promesas
  const promises = amountShippingAndValues.map(async (shippingValues) => {
      try {
          const total_order_price = shippingValues?.totalAmount;
          // Si se tiene la informacion de metodo de pago y descuentos de cada item, aca se pueden obtener
          const payment_method = 1;
          const discount_type = 2;
          const discount_value = 0;

          // Obtener los datos del documento del vendedor por ID
          const userDocumentData = await getDocumentById("seller_users", shippingValues.related_seller_id);
          // Encontrar la dirección de envío en los datos del documento
          const findedAddress = userDocumentData.shipping_address.find(
              (shippingAddress) => shippingAddress.id === shippingValues.related_shipping_address_id
          );
          const originCityCode = findedAddress?.city?.city_code; // Obtener el código de la ciudad de origen de la cotizacion

          // Crear el cuerpo de la solicitud para la cotización
          const body = {
              city_code: destinyCustomerCityCode,
              total_order_price,
              payment_method,
              discount_type,
              discount_value,
              originCityCode,
              itemName: shippingValues.productNames,
              products_details: checkoutValues?.products_details,
              related_seller_business_id: checkoutValues?.related_seller_business_id,
          };
          console.log("body,", body)
          const endpoint = "quoteShoppex";
          // Realizar la solicitud a la función en la nube
          const quoteResult = await fetchFromCloudFunction(endpoint, body, "POST");
          const quoteShoppexValue = quoteResult.data.resQuote.resQuote;
          console.log("RESULTADO COTIZAR", quoteResult.data.resQuote.resQuote, quoteResult?.data?.success && quoteResult.status === 200);
          if(quoteResult?.data?.resQuote?.isOrderAndProductsOwner)
          setCheckoutValues((prevValues) => ({
            ...prevValues,
            is_order_and_products_owner: quoteResult?.data?.resQuote?.isOrderAndProductsOwner,
            comission_per_order_and_products_owner: quoteResult?.data?.resQuote?.comissionOrderAndProductsOwner
            }));
          // Si la solicitud fue exitosa, agregar el resultado al array de resultados
          if (quoteResult?.data?.success && quoteResult.status === 200) {
              results.push({
                  related_shipping_address_id: shippingValues.related_shipping_address_id,
                  quote: quoteShoppexValue,
              });
          } else {
              throw new Error(`Request failed with status ${quoteResult.status}`);
          }
      } catch (error) {
          console.error(error); // Imprimir el error en la consola si algo falla
      }
  });

  // Esperamos a que todas las promesas se resuelvan
  await Promise.all(promises);

  // Añadir las cotizaciones a los resultados originales
  results.forEach(result => {
      const matchingAddress = amountShippingAndValues.find(address => address.related_shipping_address_id === result.related_shipping_address_id);
      if (matchingAddress) {
          matchingAddress.quote = result.quote;
      }
  });

  calculateValues(results); // Calcular valores basados en los resultados
  setGlobalLoading(false); // Establecer el estado de carga global a false

  // Verificamos si hay resultados acumulados y actuamos en consecuencia
  if (results.length > 0) {
      toastFunction(toast, "success", "Cotizaciones realizadas exitosamente", "", 3000);
      return amountShippingAndValues; // Devolver los valores de envío y cotizaciones
  } else {
      toastFunction(toast, "error", "Error al crear pedidos", "", 3000);
      return amountShippingAndValues; // Devolver los valores de envío originales
  }
};

  
  const handleCitySelect = async (city) => {
    setSelectedCity(city); 
    const amountShippingAndValues =  amountDispatchDestinies(checkoutValues.products_details)
    await quoteProducts(amountShippingAndValues, city.city_code) 
  };

  const handleSubmitCheckout = async (e) => {
    // Ajustamos los valores que formaran el cuerpo de la solicitud
      setGlobalLoading(true);
      e.preventDefault();
      const body = getBody()
      console.log("body antes de crear", body, totalProductsValue);
      try {
        // const endpoint = "simpleSeller/generateShoppexOrder";
        const endpoint = "simpleSeller/generateShoppexOrder";

        const createOrderResult = await fetchFromCloudFunction( endpoint, body, "POST" );
        console.log("RespuestacreateOrderResult", createOrderResult);
        if (createOrderResult.status === 200) {
            setShoppingCart([]);
          if(!isCatalog) {
            toastFunction(toast, "success", "Pedido creado exitosamente", "", 3000);
          }else {
            const thankyouPageRoute = `/thankyouPage/?catalogId=${catalogData?.id}&checkoutId=${checkoutValues.id}` ;
            navigate(thankyouPageRoute)
          }
          onClose();
          
        }
      } catch (error) {
        console.error(error)
        // setRowloading((prevLoading) => prevLoading.filter((id) => id !== rowData.id));
      }
      setGlobalLoading(false); 
  };


  useEffect(() => {
    const loadInitialData = async () => {
      try {
        console.log(userData)
        calculateValues();
        if( userData) {
          const unsubscribeCustomers = await getCustomers();
          return () => {
            unsubscribeCustomers && unsubscribeCustomers();
          };

        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setGlobalLoading(false)
      }
    };

    loadInitialData();
  }, []);

    return (
        <form onSubmit={handleSubmitCheckout} className="gap-4 grid grid-cols-1 ">
          <div>
            <h4 className="mb-4"> Metodo de pago</h4>
            <BadgetCard
              key={1}
              data={"badgetValues"}
              Icon={"RiHome2Line"}
              bold_text={t("paymentDelivery.bold")}
              regular_text={t("paymentDelivery.regular")}
              color={"c752c7"}
            />
            {catalogData?.free_shippings === true && isCatalog && (<BadgetCard
              key={2}
              data={"badgetValues"}
              Icon={"HiOutlineTruck"}
              bold_text={"Envio gratis "}
              regular_text={"propuesto por el vendedor"}
              color={"2d7af6"}
            />)}
          </div>
          <div>
            <h4 className="mb-4 mt-2 ">{ isCatalog ? "Ingresa tus datos para el envio": "Selecciona y/o ingresa los datos de tu cliente para el despacho" }</h4>
            {!isCatalog && (<CustomSelect
              floatLabel={true}
              optionLabel={"first_names"}
              options={relatedCustomers}
              valueTemplate={""}
              itemTemplate={""}
              value={selectedCustomer}
              placeholder={t("input-placeholders.relatedCustomers")}
              onChange={(e) => handleCustomerSelect(e.target.value)}
              disabled={relatedCustomers?.length === 0 || globalLoading}
            />)}
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={checkoutValues.customer_first_name}
              placeholder={t("input-placeholders.firstNames")}
              onChange={(e) => handleCheckoutValueChange("customer_first_name", e.target.value)}
              disabled={globalLoading}
              required={true}
            />
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={checkoutValues.customer_last_name}
              placeholder={t("input-placeholders.lastNames")}
              onChange={(e) => handleCheckoutValueChange("customer_last_name", e.target.value)} 
              disabled={globalLoading}
              required={true}
            />
              <CustomSelect
                  floatLabel={true}
                  optionLabel={"name"}
                  options={countries}
                  valueTemplate={""}
                  itemTemplate={""}
                  value={country}
                  placeholder={t("input-placeholders.country")}
                  onChange={(e) => {
                    setCheckoutValues({
                      country_code: e.target.value.code,
                    })
                    setCountry(e.target.value)
                  }}
                  disabled={true}
                />
            {/* Select de provincias */}

              <CustomSelect
                  floatLabel={true}
                  optionLabel={"province_name"}
                  options={defProvinces(zones)}
                  valueTemplate={""}
                  itemTemplate={""}
                  value={department}
                  placeholder={t("input-placeholders.province")}
                  onChange={(e) => {
                    setSelectedCity({
                      city_name: "Selecciona una ciudad",
                      city_code: ""
                    })
                    setDepartment(e.target.value)
                  }}
                disabled={globalLoading}
                />
            {/* Select de ciudades */}
              <CustomSelect
                floatLabel={true}
                optionLabel={"city_name"}
                options={cities}
                valueTemplate={""}
                itemTemplate={""}
                value={selectedCity}
                placeholder={t("input-placeholders.city")}
                onChange={(e)=> handleCitySelect(e.target.value)}
                disabled={globalLoading}

              />
            
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={checkoutValues?.customer_shipping_addres_line_1}
              placeholder={t("input-placeholders.customerShipping")}
              onChange={(e) => handleCheckoutValueChange("customer_shipping_addres_line_1", e.target.value)}
              required={true}
              disabled={globalLoading}
            />
            <CustomInputText
                floatLabel={true}
                type={"number"}
                value={checkoutValues.customer_phone_number}
                placeholder={t("input-placeholders.customerPhone")}
                onChange={(e) => handleCheckoutValueChange("customer_phone_number", e.target.value)}
                disabled={globalLoading}
                
              />
            <CustomInputText
              floatLabel={true}
              type={"email"}
              value={checkoutValues.contact_email}
              placeholder={t("input-placeholders.customerEmail")}
              onChange={(e) => handleCheckoutValueChange("contact_email", e.target.value)}
              // disabled={dissabledTagName}
              required={false}
              disabled={globalLoading}
            />
          </div>
          
          <div>
            <h4> Resumen del pedido </h4>
            <UserMoneyDataCard moneyData={ {total : totalProductsValue, title :"TOTAL A PAGAR", itemsTable: tableColumnsData}}/>          
          </div>

          <div className="grid gap-4 mt-12">
            <MainButton
                type="submit"
                label={t("createOrder")}
                loading={globalLoading}
              />
          </div>
        </form>
    )

}
CheckoutShippingCartForm.propTypes = {
  toast: PropTypes.object,
  onClose: PropTypes.func,
  isCatalog: PropTypes.bool,
  catalogData: PropTypes.object,
}
export default CheckoutShippingCartForm;
