/* eslint-disable react/prop-types */
import { useState } from 'react';
import CustomTable from '../CustomTable/CustomTable';
import { TASKS_CONFIG } from '../../pages/PQRs/tasksConfig/tasksConfig';

const PQRCardTables = ({ ordersData, orderColumns, pqrTableData, pqrTableColumns }) => {
    console.log("  pqrTableData, pqrTableColumns", pqrTableData, pqrTableColumns)
  // Estado para manejar las selecciones de cada tabla
  const [rowCheckBox, setRowCheckBox] = useState([]);
  const [secondRowCheckBox, setSecondRowCheckBox] = useState([]);

  const tareas = TASKS_CONFIG[pqrTableData.PQR_type] || TASKS_CONFIG["RECIBIDO"];

  // const ejecutarAccion = async (accion) => {
  //   try {
  //     await accion(pqrTableData, { comentario: "Accion ejecutada desde la interfaz"})
  //   } catch (error) {
  //     console.error("Error al ejecutar la accion", error)
  //   }
  // }


  return (
    <div className="card-container p-4 bg-white shadow-md rounded-lg">
      <CustomTable
        selection={rowCheckBox}
        onSelectionChange={(e) => setRowCheckBox(e.value)}
        className="custom-table mb-6"
        columns={orderColumns}
        data={ordersData}
        action={() => { /* acción para la primera tabla si es necesario */ }}
      />

      <h2 className="text-xl font-semibold mb-4">PQR Info para {pqrTableData.tipo || "Recibido"} </h2>
      <CustomTable
        selection={secondRowCheckBox}
        onSelectionChange={(e) => setSecondRowCheckBox(e.value)}
        className="custom-table"
        columns={pqrTableColumns}
        data={tareas}
        action={()=> {}}
      />
    </div>
  );
};

export default PQRCardTables;
